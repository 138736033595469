var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("Header", {
        attrs: {
          title: "Incidência para o FGTS",
          subtitle: "Gerenciamento das atualizações dos leiautes do e-Social",
          itemsBreadCrumb: _vm.itemsBreadCrumb,
          hasUpdateInfo: false,
          hasAddButton: true,
          addButtonText: "Adicionar incidência",
        },
        on: { clickAdd: _vm.openModal },
      }),
      _c(
        "div",
        { attrs: { id: "tables-container" } },
        [
          _c(
            "v-card",
            { staticClass: "pl-0 pr-0 pt-0 pb-0" },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.incidences,
                  loading: _vm.loading,
                  "no-data-text": _vm.noDataText,
                  options: _vm.options,
                  "footer-props": {
                    "items-per-page-options": [50, 100, 250],
                    itemsPerPageText: `Incidência por página: `,
                    disableItemsPerPage: _vm.incidences.length < 50,
                  },
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "footer.page-text",
                    fn: function (items) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(items.pageStart) +
                            " - " +
                            _vm._s(items.pageStop) +
                            " de " +
                            _vm._s(_vm.totalIncidences) +
                            " incidências "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.name",
                    fn: function ({ item }) {
                      return [
                        _vm.checkLength(item.name, 75)
                          ? _c(
                              "AGTooltip",
                              { attrs: { tooltipText: item.name } },
                              [
                                [
                                  _c("span", { staticClass: "text-no-wrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.truncateText(item.name, 75)
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                              ],
                              2
                            )
                          : _c("div", [
                              _c("span", { staticClass: "text-no-wrap" }, [
                                _vm._v(" " + _vm._s(item.name) + " "),
                              ]),
                            ]),
                      ]
                    },
                  },
                  {
                    key: "item.user",
                    fn: function ({ item }) {
                      return [
                        _vm.checkLength(item.user)
                          ? _c(
                              "AGTooltip",
                              { attrs: { tooltipText: item.user } },
                              [
                                [
                                  _c("span", { staticClass: "text-no-wrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.truncateText(item.user)) +
                                        " "
                                    ),
                                  ]),
                                ],
                              ],
                              2
                            )
                          : _c("div", [
                              _c("span", { staticClass: "text-no-wrap" }, [
                                _vm._v(" " + _vm._s(item.user) + " "),
                              ]),
                            ]),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: { icon: "", id: "idEdit" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openModalEdit(item)
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "success",
                                              },
                                            },
                                            [_vm._v(" mdi-pencil-outline ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Editar")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                icon: "",
                                                id: "idHistoric",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openModalHistoric(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { small: "" },
                                            },
                                            [_vm._v(" mdi-history")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Histórico")])]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ModalIncidenceFGTSNew", {
        ref: "modalIncidenceFGTSNew",
        attrs: { incidences: _vm.incidences },
        on: { refresh: _vm.refreshFetch },
      }),
      _c("ModalIncidenceFGTSEdit", {
        ref: "modalIncidenceFGTSEdit",
        attrs: { incidence: _vm.incidence },
        on: { refresh: _vm.refreshFetch },
      }),
      _c("ModalIncidenceFGTSHistoric", {
        ref: "modalIncidenceFGTSHistoric",
        attrs: { history: _vm.arrHistoric },
      }),
      _c("ModalIncidenceFGTSHistoricUpdate", {
        ref: "modalIncidenceFGTSHistoricUpdate",
        attrs: { history: _vm.arrHistoric },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }